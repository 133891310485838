<template>
    <div class="stories-container">
        <div
            v-for="shorts in shortsList"
            :key="shorts.no"
            class="story-circle"
            :class="{ 'story-clicked': clickedStories.has(shorts.content_no) }"
            :style="{
                '--story-size': props.options.size + 'px',
                position: 'relative',
                userSelect: 'none',
            }"
        >
            <div class="story-content">
                <img
                    draggable="false"
                    :src="shorts.ui.thumb_url"
                    :alt="shorts.ui.subject"
                />
            </div>
            <div class="story-circle-wall" :id="'story-' + shorts.content_no" />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { useL24Store } from "../store/l24";

const props = defineProps({
    options: {
        type: Object,
    },
});

const store = useL24Store();
const userNo = ref("");
const userType = ref("");
const clickedStories = ref(new Set());

const STORAGE_KEY = "clicked_circle_stories";

const saveClickedStories = () => {
    const clickedData = Array.from(clickedStories.value).map((id) => ({
        id,
        expiration: new Date().getTime() + 3 * 24 * 60 * 60 * 1000, // 3일 후
    }));
    localStorage.setItem(STORAGE_KEY, JSON.stringify(clickedData));
};

const loadClickedStories = () => {
    const storedStories = localStorage.getItem(STORAGE_KEY);
    if (storedStories) {
        const parsedStories = JSON.parse(storedStories);
        const now = new Date().getTime();
        const validStories = parsedStories.filter(
            (item) => item.expiration > now,
        );
        clickedStories.value = new Set(validStories.map((item) => item.id));

        // 만료된 항목이 있으면 저장된 데이터 업데이트
        if (validStories.length !== parsedStories.length) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(validStories));
        }
    }
};

const findSurlByContentNo = (contentNo) => {
    const shorts = store.shorts.find((short) => short.content_no === contentNo);
    return shorts.select_product_list[0].surl;
};

const handleStoryClick = (shorts) => {
    const contentNo = shorts.split("-")[1];

    if (!clickedStories.value.has(contentNo)) {
        clickedStories.value.add(contentNo);
    }
    saveClickedStories();

    window.open(findSurlByContentNo(contentNo), "_blank");
};

const initSetUp = async () => {
    await store.getL24Config();
    if (store.l24config) {
        userNo.value = store.l24config.user_no;
        userType.value = store.l24config.type;
    } else {
        userNo.value = props.options.userNo;
        userType.value = props.options.type;
    }

    const params = {
        row_count: 10,
        order_col: "view_cnt",
        cache_time: 5,
        user_no: userNo.value,
        type: "shorts",
        have_product: "Y",
        only_shortform: "Y",
    };
    await store.getShortsList(params);

    loadClickedStories();
};

// mouse touch scroll
const setMouseScroll = () => {
    let isDown = false;
    let startX;
    let scrollLeft;

    const container = document.querySelector(".stories-container");

    container.addEventListener("mousedown", (e) => {
        isDown = true;
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
    });

    container.addEventListener("mouseleave", () => {
        isDown = false;
    });

    container.addEventListener("mouseup", (e) => {
        isDown = false;
        const dragX = Math.abs(startX - e.pageX);

        if (dragX < 5) {
            handleStoryClick(e.target.id);
        }
    });

    container.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = x - startX; // scroll-fast
        container.scrollLeft = scrollLeft - walk;
    });
};

const shortsList = computed(() => store.shorts);

watch(
    clickedStories,
    () => {
        saveClickedStories();
    },
    { deep: true },
);

onMounted(async () => {
    initSetUp();

    await nextTick();

    setMouseScroll();
});
</script>

<style scoped>
body {
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

.stories-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    gap: 15px;
}

.story-circle {
    --story-size: 60px; /* 기본값, props로 덮어씌워짐 */
    --border-thickness: 3px;
    --white-border-thickness: 3px;

    flex: 0 0 auto;
    width: calc(var(--story-size) + 2 * var(--border-thickness));
    height: calc(var(--story-size) + 2 * var(--border-thickness));
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    padding: var(--border-thickness);
    position: relative;
    cursor: pointer;
}

.story-circle-wall {
    position: absolute;
    width: calc(var(--story-size) + 2 * var(--border-thickness));
    height: calc(var(--story-size) + 2 * var(--border-thickness));
    background-color: transparent;
    border-radius: 50%;
}

.story-circle::before {
    content: "";
    position: absolute;
    top: var(--border-thickness);
    left: var(--border-thickness);
    right: var(--border-thickness);
    bottom: var(--border-thickness);
    border-radius: 50%;
    background: white;
}

.story-circle.story-clicked {
    background: #f0f0f0;
}

.story-circle.story-clicked::before {
    content: "";
    position: absolute;
    top: var(--border-thickness);
    left: var(--border-thickness);
    right: var(--border-thickness);
    bottom: var(--border-thickness);
    border-radius: 50%;
    background: white;
}

.story-content {
    position: absolute;
    top: calc(var(--border-thickness) + var(--white-border-thickness));
    left: calc(var(--border-thickness) + var(--white-border-thickness));
    right: calc(var(--border-thickness) + var(--white-border-thickness));
    bottom: calc(var(--border-thickness) + var(--white-border-thickness));
    border-radius: 50%;
    overflow: hidden;
}

.story-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
